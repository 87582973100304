html,
body {
  scroll-snap-type: y mandatory;
}

.intro {
  background: rgb(108,184,168);
  background: linear-gradient(180deg, rgba(108,184,168,1) 0%, rgba(42,174,194,1) 100%);
  height: 100vh;
  background-image: url('../img/bg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.intro p {
  padding: 0 0 20px 0;
  margin: 0;
  text-shadow: 0 0 3px #fff;
}

.language-selection {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2em;
}

.language-selection a {
  padding: 0 10px;
  color: #fff;
  text-decoration: none;
  transition: text-shadow 500ms;
}

.language-selection a:hover {
  text-shadow: 0px 0px 10px #fff;
}

.intro ul li {
  float: left;
  width: 100px;
}

.intro a.more {
  width: 50px;
  height: 50px;
  display: inline-flex;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 25px);
  background: #f9b417;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
}

.intro a .arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.intro a .down {
  transform: rotate(45deg);
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(42,174,194);
  background: linear-gradient(180deg, rgba(42,174,194,1) 0%, rgba(42,174,194,1) 50%, rgba(255,255,255,1) 93%);

  font-family: 'Caveat', cursive;

  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 14px;
  }

  body section {
    padding: 1em;
  }

  p {
    padding: 1em 0;
  }
}

.intro h1 {
  font-size: 3em;
  text-shadow: 0 0 3px #fff;
  font-weight: 700;
}

.intro p {
  max-width: 1000px;
  font-size: 2em;
}

section {
  scroll-snap-align: start;
  min-height: 100vh;
  width: 100%;
  margin: 0px auto;
}

section, section .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
}

section .content {
  margin: 0 auto;
  align-items: start;
  padding: 0 30px;
  max-width: 1000px;
}

.deck {
  --amount-of-cards: 22;

  font-size: 1.5em;
  padding: 10px;
  box-sizing: border-box;
  margin: 15px auto 0;
  max-width: 90vw;
  background: #1a8696;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #000000;
}

@media only screen and (min-width: 1200px) {
  .deck {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    font-size: 3em;
  }
}

.deck .card {
  --side-length: calc(70vw / 4);
  --min-side-length: 80px;
  --max-side-length: 150px;
  perspective: 1000px;
  display: flex;
  height: var(--side-length);
  width: var(--side-length);
  max-width: var(--max-side-length);
  max-height: var(--max-side-length);
  color: #ffffff;
  cursor: pointer;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  transition: transform 0.3s ease;
  position: relative;
}

.deck .card .front,
.deck .card .back {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.deck .card .front {
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: transform 400ms linear;
  background-size: cover;
}

.deck .card .front span {
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  text-shadow: 2px 2px 5px #000;
}

.deck .card .back {
  background: #25ADC0;
  position: absolute;
  transform: rotateY(0deg);
  backface-visibility: hidden;
  transition: transform 400ms linear;
}

.deck .card.notmatch .front,
.deck .card.match .front,
.deck .card.show .front,
.deck .card.open .front {
  transform: rotateY(0deg);
  cursor: pointer;
}

.deck .card.notmatch .back,
.deck .card.match .back,
.deck .card.open .back {
  transform: rotateY(-180deg);
  cursor: pointer;
}

.deck .card.match .back {
  /*transform: rotateY(0);*/
  /*cursor: default;*/
  /*background: #9BCB3C;*/
  /*font-size: 33px;*/
  /*box-shadow: 14px 14px 0 0 #9BCB3C;*/
}

.deck .card.notmatch .back {
  /*box-shadow: 14px 14px 0 0 #EE0E51;*/
}

#score-panel {
  text-align: left;
  font-size: 1.5em;
  width: 345px;
  margin-bottom: 10px;
}

#score-panel .stars {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin: 0 5px 0 0;
}

#score-panel .stars li {
  list-style: none;
  display: inline-block;
}

#score-panel .stars .off {
  color: #24adc1;
}

#score-panel .restart {
  float: right;
  cursor: pointer;
}

*::-moz-selection {
  background: transparent;
}

*::selection {
  background: transparent;
}

.swal2-overlay {
  background-color: white;
}
